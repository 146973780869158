
import { defineComponent } from 'vue';
import { ShipmentStatus } from '@/typings/enums';
import { globalDateTimeFormat } from '@/constants';
import Skeleton from 'primevue/skeleton';
import TimelineSegment from '@/components/main/TimelineSegment.vue';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export default defineComponent({
  name: 'RowDetailTimeline',
  components: {
    Skeleton,
    TimelineSegment
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    loaded: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      historyOpen: false,
      dayjs: dayjs,
      globalDateTimeFormat: globalDateTimeFormat
    };
  },
  computed: {
    status(): ShipmentStatus {
      if (!this.data || !this.data.state) {
        return ShipmentStatus['NA'];
      }
      return ShipmentStatus[this.data.state];
    },
    lateness(): { status: string; offset: string; reasonCode: string; reason: string } {
      if (this.data && this.data.lateness) {
        let status: string;
        //null scenarios
        if (!this.data.lateness.status) {
          if (this.data.lateness.amount > 0) {
            status = 'LATE';
          } else {
            status = 'EARLY';
          }
        } else {
          status = this.data.lateness.status;
        }
        const amount = Math.abs(this.data.lateness.amount) || 0;
        const unit = amount > 23 ? Math.floor(amount / 24) + ' Days' : amount + ' Hours';
        const reasonCode = this.data.lateness.reasonCode;
        const reason = this.data.lateness.reason;
        return { status: status, offset: unit, reasonCode: reasonCode, reason: reason };
      }
      return { status: 'N/A', offset: '0', reasonCode: '', reason: '' };
    }
  },
  methods: {
    dateTzAndFormat(date: string) {
      // some dates come sometimes wrong, like 0023, this makes the timezone parser to fail, here we check for that. BUG #403657
      if (!date || new Date(date).getTime() <= 0) {
        return 'Not found';
      }
      return dayjs.tz(date).format(globalDateTimeFormat);
    },
    closeDialog() {
      this.$store.dispatch('SET_SHOW_DETAILS', false);
    },
    orderCarrierEtaList(arr) {
      return arr.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
    },
    getProgressStatus(items, index: number) {
      let highestconfirmed = 0;
      for (let i = 0; i < items.length; i++) {
        if (items[i].actualArrival || items[i].actualDeparture) {
          highestconfirmed = i;
        }
      }
      if (index < highestconfirmed) {
        return 'timeline__items__item--completed';
      }
      if (items[index].actualDeparture && items[index + 1] && !items[index + 1].actualDeparture && !items[index + 1].actualArrival) {
        return 'timeline__items__item--departed';
      }
      if (items[index].actualDeparture || (items[index + 1] && (items[index + 1].actualDeparture || items[index + 1].actualArrival))) {
        return 'timeline__items__item--completed';
      }
      if (items[index].actualArrival) {
        return 'timeline__items__item--arrived';
      }
    },
    getIntermediateClass(item) {
      if (item.segmentClassifier == 'INTERMEDIATE') {
        return 'timeline__items__item--intermediate';
      }
      return 'timeline__items__item';
    }
  }
});
